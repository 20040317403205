import React from 'react';
import LazyLoad from 'react-lazyload';
import { Link } from "react-router-dom";
import { ProjectData } from "../../data/ProjectData";

export default function Project() {
    return (
        <>
            <section className="dark:bg-gray-900 py-10">
                <div className="container">
                    {ProjectData.map((project, idx) => {
                        // console.log(idx);
                        return (

                            <Link to={`project/${project.id}`} key={project.id}>
                                <div className="flex flex-col md:flex-row space-x-0 md:space-x-16 space-y-6 md:space-y-0 mb-20 justify-start items-start lg:items-start lg:justify-start">
                                    <div className="group overflow-hidden w-full md:w-1/2">
                                        <LazyLoad height={200}>
                                            <img src={project.image} alt={project.title} className="transition-all duration-300 group-hover:scale-[1.2]" />
                                        </LazyLoad>
                                    </div>
                                    <div className="flex justify-start items-start flex-col space-y-6 dark:text-white w-full md:w-1/2">
                                        <div className="flex flex-col space-y-2">
                                            <h1 className=" text-3xl font-bold font-poppins">{project.title}</h1>
                                            <h1 className="text-[1.4rem] font-bold text-taupe-gray">
                                                {project.mode}
                                            </h1>
                                        </div>
                                        <div className="flex flex-col space-y-2 text-[1.12rem] font-roboto">
                                            {project.feature.map((item, idx) => {
                                                return (

                                                    <h1 key={idx}>{item}</h1>

                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </Link>

                        );
                    })}
                </div>
            </section>
        </>
    );
}
