import { useParams } from "react-router-dom";
import ImageView from "../../../../components/ImageVIew/ImageView";
import VideoView from "../../../../components/VideoView/VideoView";
import { WorkData } from "../../../../data/WorkData";

export default function Experiment() {
    let { project_id } = useParams();
    let data = null;
    if (WorkData[project_id]) data = WorkData[project_id].experiment;
    return (
        <>
            {data && <div id={data.id} className=" dark:text-white">
                <h1 className="text-2xl font-poppins font-semibold">{data.title}</h1>
                <div className="flex flex-col space-y-4 mt-3">
                    <p className="text-lg">{data.description}</p>
                    <ImageView data={data} />
                    <VideoView data={data} />
                </div>
            </div>}
        </>
    );
}
