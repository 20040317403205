import TimeLineItem from "../../../components/TimeLine/TImeLIneItem";
import { AboutData } from "../../../data/AboutData";

export default function TimeLine() {
    let timeline = AboutData.timeline;
    return (
        <>
            <div id={timeline.id} className="dark:text-white">
                <div className="flex flex-col space-y-5">
                    <h1 className="text-2xl font-poppins font-semibold">{timeline.name}</h1>
                    <h1 className="text-xl font-normal">{timeline.description}</h1>
                </div>
                <div className="flex flex-col space-y-0 mt-6 translate-x-0 ">
                    <TimeLineItem items={timeline.items} />
                </div>
            </div>
        </>
    );
}
