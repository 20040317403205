import { AboutData } from "../../../data/AboutData";

export default function SkillSet() {
    let skill_set = AboutData.skill_set;

    return (
        <>
            <div id={skill_set.id} className=" dark:text-white">
                <h1 className="text-2xl font-poppins font-semibold">{skill_set.name}</h1>
                <div className="flex flex-col space-y-4 mt-3 translate-x-0 xl:translate-x-10">
                    <table>
                        <tbody>
                        <tr>
                            <td className="inline-flex justify-start items-start">
                                <span className="text-lg font-bold text-taupe-gray ">
                                    Design
                                </span>
                            </td>

                            <td>
                                <span>
                                    <span className="text-lg text-taupe-gray">:</span>{" "}
                                    {skill_set.design}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td className="inline-flex justify-start items-start">
                                <span className="text-lg font-bold text-taupe-gray">Tools</span>
                            </td>
                            <td>
                                <span>
                                    <span className="text-lg text-taupe-gray">:</span>{" "}
                                    {skill_set.tools}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td className="inline-flex justify-start items-start">
                                <span className="text-lg font-bold text-taupe-gray">
                                    Tech stack{" "}
                                </span>
                            </td>
                            <td>
                                <span>
                                    <span className="text-lg text-taupe-gray">:</span>{" "}
                                    {skill_set.tech_stack}
                                </span>
                            </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
