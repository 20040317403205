import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import BlogSection from "./BlogSection";
import HeroSection from "./HeroSection";
import Project from "./Project";

export default function HomeContainer() {
    return (
        <>
            {/* header  */}
            <Header />
            {/* hero section  */}
            <HeroSection />
            {/* project  */}
            <Project />
            {/* blog section  */}
            <BlogSection />
            {/* footer  */}
            <Footer />
        </>
    );
}
