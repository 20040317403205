export default function TimeLineItem({ items }) {
    return (
        <>
            {items.map((item, idx) => {
                return (
              
                    <div className="flex space-x-4 justify-start items-start before:w-[2px] before:h-full before:absolute relative before:bg-light-silver after:w-3 after:h-3 after:bg-primary after:absolute after:left-[-5px] after:top-2 before:top-4 pb-7" key={idx}>
                            {/* <div className="mt-2">
                                <p className="w-3 h-3 rounded-full bg-primary"></p>
                            </div> */}
                            <div className="flex flex-col space-y-5 ml-4">
                                <div className="flex flex-col space-y-1">
                                    <h1 className="text-2xl">{item.title}</h1>
                                    <div className="flex flex-col text-quartz">
                                        <span>
                                            <span className=" text-[20px]">{item.company}</span>
                                            <span> - </span>
                                            <span>{item.hour}</span>
                                        </span>
                                        <span>{item.date}</span>
                                        <span> {item.location}</span>
                                    </div>
                                </div>
                                <p className="text-lg">{item.description}</p>
                            </div>
                        </div>
                 
                );
            })}
        </>
    );
}
