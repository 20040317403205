import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter
} from "react-router-dom";
import App from './App';
import { ThemeProvider } from "./context/ThemeContext";
import "./style/custom.css";
import "./style/index.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <React.Fragment>
      <ThemeProvider>
        <App />
        </ThemeProvider>
    </React.Fragment>
  </BrowserRouter>
);

