import LazyLoad from 'react-lazyload';

export default function VideoView({ data }) {
    return (
        <>
            <div className=" mt-10">
                {data.videos && data.videos.map((item, idx) => {
                    return (
                        <div key={idx} className="mr-3 mt-3">
                            <LazyLoad height={200}>
                                <embed
                                    src={item}
                                    wmode="transparent"
                                    type="video/mp4"
                                    className="!w-[full] h-[720px]"
                                    width="100%"
                                    allow="autoplay; encrypted-media; picture-in-picture"
                                    allowfullscreen
                                    title="Keyboard Cat"
                                />
                                </LazyLoad>
                        </div>
                    );
                })}
            </div>
       
          
        </>
    )
}