import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { AboutData } from "../../data/AboutData";
import AboutContent from "./AboutContent";
import AboutHero from "./AboutHero";
import VerticalMenu from "./VerticalMenu";

export default function AboutContainer() {
    let data = AboutData;
    return (
        <>
            {data ? (
                <>
                    {/* header  */}
                    <Header />
                    {/* about hero section  */}
                    <AboutHero />
                    {/* about body content  */}
                    <AboutContent />
                    {/* vertical menu  */}
                    <VerticalMenu />
                    {/* footer  */}
                    <Footer />
                </>
            ) : (
                <>
                    {" "}
                    <section class="col-span-6 mb-10">
                        <div class="container w-7xl mx-auto">
                            <div class="mx-[.7rem] md:mx-[1rem] lg:mx-[2rem] xl:mx-[4rem] 2xl:mx-[10rem]"></div>
                            <div class="flex flex-col justify-center items-center">
                                <div>
                                    <img
                                        src="https://img.pikbest.com/png-images/qianku/404-error-model_2369179.png!bw700"
                                        alt=""
                                    />
                                </div>
                                <h1 class="text-7xl font-bold">404</h1>
                                <h1 class="text-3xl md:text-4xl lg:text-6xl font-bold text-center">
                                    Not found!
                                </h1>
                            </div>
                        </div>
                    </section>
                </>
            )}
        </>
    );
}
