import Role from "./content/Role";
import Scope from "./content/Scope";
import Understanding from "./content/Understanding";
import User from "./content/User";

export default function WorkContentUp() {
    return (
        <>
            <section className="dark:bg-gray-900 py-10">
                <div className="container">
                    <div className="flex flex-col space-y-16">
                        {/* role section  */}
                        <Role />
                        {/* understan section  */}
                        <Understanding />
                        {/* User section  */}
                        <User />
                        {/* scope section  */}
                        <Scope />
                    </div>
                </div>
            </section>
        </>
    );
}
