import { BsFillArrowUpCircleFill } from 'react-icons/bs';
import { useScrollPosition } from '../../hooks/useScrollPosition';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ScrollToTop() {
    let scrollposition = useScrollPosition();

    function goToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return (<>
        <section className=''>
            <div className={classNames(scrollposition < 100 ? 'hidden w-0 h-0' : 'fixed right-5 bottom-[3rem] z-[100] group', 'transition-all duration-300 ease-linear')}>
                <button onClick={goToTop} className='text-primary flex-col space-y-2 inline-flex justify-center items-center group-hover:bg-white dark:text-white group-hover:text-black transition-all duration-300  bg-lime-green group-hover:border-taupe-gray'>
                    <BsFillArrowUpCircleFill className='w-7 h-7' />
                </button>
            </div>
        </section>
    </>)
}