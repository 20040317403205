import { useParams } from "react-router-dom";
import { WorkData } from "../../../../data/WorkData";

export default function PostlaunchExperiment() {
    let { project_id } = useParams();
    let data = null;
    if (WorkData[project_id]) data = WorkData[project_id].postlaunchexperiment;
    return (
        <>
            {data && <div id={data.id} className=" dark:text-white">
                <h1 className="text-2xl font-poppins font-semibold">{data.title}</h1>
                <div className="flex flex-col space-y-4 mt-3">
                    <p className="text-lg">{data.description}</p>
                    <ul className="list-disc translate-x-0 sm:translate-x-10">
                        {data.content.map((item, idx) => {
                            return (
                                    <li className="text-xl leading-8" key={idx}>
                                        {item}
                                    </li>
                            );
                        })}
                    </ul>
                </div>
            </div>}
        </>
    );
}
