import DarkSwitch from "../DarkSwitch/DarkSwitch";
import ScrollToTop from "./ScrollToTop";

export default function Footer() {
    return (
        <>
            <footer className="dark:bg-gray-900 bg-gray-50 py-10 relative">
                <div className="container">
                    <div className="flex flex-col space-y-6 dark:text-white">
                        <h1 className=" text-2xl">uxatik.com and Me (😊)</h1>
                        <h1 className="text-lg flex flex-col space-y-3">
                            <span>
                                I have been crafting uxatik.com with ReactJS. This site itself has been a challenging and rewarding web design project. Here I have the chance to sit down, collect the pieces, and document my journey as a designer. uxatik.com will always be under construction and I am enjoying every bit of the process.

                            </span>
                            <span>
                                I love connecting with minds from all specialties. If you have crazy (or any) ideas to bounce with me, <a href="https://calendly.com/atikur-ux/15min" target="_blank" rel="noreferrer"><span className="font-bold text-primary">Let's talk!</span></a>
                            </span>
                        </h1>
                        <h1 className="text-lg">
                            Design and code by Atik <span className="text-primary font-semibold">©</span> {new Date().getFullYear()}
                        </h1>
                    </div>
                </div>
                {/* dark switch  */}
                <DarkSwitch />
                {/* scroll to top  */}
                <ScrollToTop />
            </footer>
        </>
    )
}