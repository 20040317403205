export const AboutData = {
    about_hero: {
        id: "about_me",
        menu_name: "About Me",
        title: "My name is Atikur Rahman.",
        description:
            "I am known for transforming ideas into a visualization to build intuitive human-centered digital world. It starts from understanding what the user needs and ideating an innovative solution for users. Besides, being a designer I am also known as an engineer to implements front-end. I prefer to live in a world where design & tech speak together :). Aside from design, I play cricket, badminton, and travels to discover the beautiful world. Based in Helsinki, Finland",
        image: "./assets/images/atik.png",
    },
    skill_set: {
        id: "skill_set",
        menu_name: "Skill Set",
        name: "Skill Set",
        design:
            "Product Design, UX, accecibility,Product Design, UX, accecibility, Product Design, UX,Pen & Paper, balsamiq, Figma",
        tools: "Pen & Paper, balsamiq, Figma",
        tech_stack: "React, ant design, styled component",
    },
    timeline: {
        id: "timeline",
        menu_name: "Timeline and Experience",
        name: "Timeline and Experience",
        description: "",
        items: [
            {
                title: "UX Designer and Front-end Developer",
                company:"Unikie",
                hour: "Full-time",
                date: "Apr 2021 - Present",
                location: "Helsinki, Uusimaa, Finland",
                description: "At Unikie, I work with a project (Port Activity App) collaboration with the Finnish govt authority (Fintrafic and various ports in Finland, Sweden, and some other parts of Europe). I am mainly responsible for transforming ideas (new features) into an intuitive user interface, ensuring pixel-perfect implementation for both web and mobile by considering human-centered design. Often act as a front-end developer to implement the design plan. So far we have implemented brand new features from sketch to live screen for both ship operation and port authority (i.e, PORT OF KOKKOLA).",
            },
            {
                title: "UX Design Mentor",
                company: "adplist.org",
                hour: "part-time",
                date: "Mar 2022 - Present",
                location: "Helsinki, Uusimaa, Finland",
                description: "Coaching & Mentoring, User Experience (UX), Front-End Development, Leadership and Public Speaking. Book a session here:  https://adplist.org/mentors/atik-rahman",
            },
            {
                title: "UX Designer and Frontend Developer",
                company: "Zapflow",
                hour: "Full-time",
                date: "May 2020 - Apr 2021 · 1 yr",
                location: "Espoo, Southern Finland, Finland",
                description: "I have introduced BEM methodology, implement the zapflow design system, create intuitive web interface, data visualization, zapflow website design & development (https://www.zapflow.com/), and implement new features of web applications using React, Reagent, JavaScript, and ClojureScript, Hiccups, HTML, CSS, SCSS.",
            },
            {
                title: "Front-end Developer",
                company: "Integrify ©",
                hour: "Full-time",
                date: "Nov 2019 - May 2020 · 7 mos",
                location: "Helsinki Area, Finland",
                description: "Integrify teaches coding to talented immigrants and connects graduates with tech jobs. Integrify provides a 6-months state-of-the-art software developer program teaching motivated and talented immigrants in modern software development and placing graduates in jobs. The course focuses on JavaScript, React/Redux and Node & Express to equip students with a skill-set to work as software developers. Teaching is led by experienced software developers which comprises up to 1000 hours of pure coding.",
            },
            {
                title: "UI Designer",
                company: "Kite Games Studio",
                hour: "Full-time",
                date: "Jan 2017 - Jul 2017 · 7 mos",
                location: "Dhaka, Bangladesh",
                description: "I was involved in all phases of product development from high-level vision to implementation that includes, concept creation, wireframing, prototyping, UI/Visual design, app icon and design specification. I designed and deployed 6 iOS applications in the Appstore.",
            },
            {
                title: "UX/UI Designer and Front-end Developer",
                company: "Nuspay™ International",
                hour: "Full-time",
                date: "Jul 2014 - Dec 2016 · 2 yrs 6 mos",
                location: "Dhaka, Bangladesh",
                description: "I have worked with wide range of fintech web and mobile applications. I designed wireframes, UI, prototyping, website, branding and front-end development for payment getaway. - Payment getaway, - Agent Banking CBS, - Uddipan Digital Bazar, - Krishifirm.com(Online Marketplace), - Twincards.com, - Family Pay(Payment app), - Nuspos(Pos app), - Smart card app for Merchant, - Smart card app for User, - FoundyYea(Location Tracking app)",
            },
            {
                title: "Graphic Designer",
                company: "Avery Dennison",
                hour: "Full-time",
                date: "Dec 2010 - Jul 2013 · 2 yrs 8 mos",
                location: "Dhaka, Bangladesh",
                description: "Design hand tags for the garments products for diverse clients such as Walmart, H&M, C&A and make ready for the final production in HP indigo printing press. - Graphic design for retail branding, - Print design, - Colors, - Print production",
            },
        ],
    },
    school: {
        id: "school",
        menu_name: "School and education",
        name: "School and education",
        description:
            "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility",
        items: [
            {
                varsity: "University of Oulu",
                degree_name:"Master of Science",
                degree: "MSc",
                date: "Aug 2017 - Dec 2019",
                description:
                    "Interaction design, HCI, Advanced human-centered design, Usability testing, Agile methodology, Research methods.",
            },
            {
                varsity: "Bangladesh University",
                degree_name: "Bachelor of Science (BSc)",
                degree: "BSc",
                date: "Apr 2010 - Nov 2014",
                description:
                    "Web programming, Ai, Design pattern, Web front-end, Web design, Smartphone application design.",
            },
            {
                varsity: "Graphic Arts Institute",
                degree_name: "Graphic Design and Printing",
                degree: "Diploma in Engineering",
                date: "2006 - Nov 2010",
                description: "Graphic design, Layout design, Printing, Raster and vector graphic, Press & media, Digital publication.",
            },
        ],
    },
};
