import SchoolItems from "../../../components/School/SchoolItems";
import { AboutData } from "../../../data/AboutData";

export default function School() {
    let school = AboutData.school;

    return (
        <>
            <div id={school.id} className="dark:text-white">
                <div className="flex flex-col space-y-5">
                    <h1 className="text-2xl font-poppins font-semibold">{school.name}</h1>
                    <h1 className="text-xl font-normal">{school.description}</h1>
                </div>
                <div className="flex flex-col space-y-0 mt-6 translate-x-0 ">
                    <SchoolItems items={school.items} />
                </div>
            </div>
        </>
    );
}
