export default function HeroSection() {
    return (
        <>
            <section className="dark:bg-gray-900 py-5  lg:py-20 ">
                <div className="container">
                    <div className="flex flex-col space-y-6">
                        <h1 className=" text-[1.9rem] sm:text-5xl font-bold dark:text-white font-poppins">
                            Hi 👋, I am Atik!
                        </h1>
                        <h1 className="dark:text-white font-roboto text-2xl leading-9">
                          I'm a UX designer with coding skills.  I strive to provide user-friendly products that solve complex problems with great Design, Prototyping, User Testing and Collaboration, while building relationships with others through empathy, humility, respect and openness.
                        </h1>
                        <div>
                            <a href="https://calendly.com/atikur-ux/15min" target="_blank" className="bg-primary px-5 py-2 text-white text-base hover:border-2 hover:border-primary hover:bg-white hover:text-black transition-all duration-300 ease-linear border-2 border-transparent dark:hover:border-white" rel="noreferrer">
                                Lets talk
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
