import { Navigate, Route, Routes } from "react-router-dom";
import About from "../pages/About";
import Blog from "../pages/Blog";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import Work from "../pages/Work";

export default function Router() {
    return (
        <>
            <Routes>
                <Route index element={<Home />} />
                <Route path="/" element={<Home />} />
                <Route path="/blog" element={<Blog />} /> 
                <Route path="/about-me" element={<About />} />
                <Route path="/project/:project_id" element={<Work />} />
                <Route path="/project/*" element={<NotFound />} />
                <Route path="*" element={<NotFound/>} />
                <Route path="*" element={<NotFound/>} />
                <Route path="/project-details-newborn.html" element={<Navigate to="/project/2" replace />} />
                <Route path="/project-details-photovault.html" element={<Navigate to="/project/4" replace />} />
                <Route path="/about-me.html" element={<Navigate to="/about-me" replace />} />
            </Routes>
        </>
    )
}