import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ImQuotesLeft } from 'react-icons/im';
import OwlCarousel from "react-owl-carousel";
import { TestimonialsData } from "../../../data/TestimonialsData";

const options = {
    margin: 30,
    autoplayHoverPause: true,
    responsiveClass: true,
    autoplay: false,
    nav: true,
    dots: true,
    loop: true,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        550: {
            items: 1,
        },
        600: {
            items: 1,
        },
        700: {
            items: 1,
        },
        1000: {
            items: 1,
        },
    },
};


export default function Testimonials() {
    return (
        <div className="flex space-y-6 flex-col">

            <div className="flex flex-col space-y-4 dark:text-white">
                <h1 className=" text-2xl font-poppins">Client Testimonials</h1>
            </div>
            <OwlCarousel
                className="owl-theme owl-testimonials"
                navText={[
                    '<i class="gg-chevron-left"></i>',
                    '<i class="gg-chevron-right"></i>',
                ]}
                {...options}
                id="testimonials"
            >
                {TestimonialsData.map((testimonial, idx) => {
                    return (
                     
                            <div className="flex item justify-between space-x-6 items-center dark:bg-gray-700 h-auto md:h-[300px]  my-2 overflow-hidden" key={idx}>
                                {/* <div className="h-full w-[20%] flex justify-center items-center">
                                    <img src={testimonial.left_image} alt="" className="w-full h-full" />
                                </div> */}
                                <div className="w-full h-full bg-white flex flex-col md:flex-row relative space-x-5 justify-center items-center md:before:h-full md:before:w-1 md:before:bg-primary md:before:absolute md:before:left-0 py-6 md:py-0">
                                    <div className="md:h-full md:w-[23%]">
                                        <img src={testimonial.image} alt="" className="w-full h-full p-10 md:p-0 rounded-full md:rounded-none " />
                                    </div>
                                    <div className="flex flex-col space-y-4 md:w-[80%]">
                                        <div className="flex flex-col space-y-2">
                                            <div>
                                                <ImQuotesLeft className="w-8 h-8 text-primary" />
                                            </div>
                                            <p>{testimonial.context}</p>
                                        </div>
                                        <div>
                                            <h1 className="text-xl font-bold">{ testimonial.name }</h1>
                                            <h1 className="text-base">{ testimonial.designation }</h1>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="w-[20%]">
                                    <img src={testimonial.right_image} alt="" className="" />
                                </div> */}
                            </div>
                      
                    );
                })}
            </OwlCarousel>

        </div>
    )
}