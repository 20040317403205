/* eslint-disable jsx-a11y/anchor-is-valid */

import { BlogData } from "../../data/BlogData";

export default function BlogItems() {
    // console.log(BlogData);
    return (
        <>
            <section className="dark:bg-gray-900 py-10">
                <div className="container">
                    {BlogData.map((blog,idx) => {
                        return (
                                <div className="flex flex-col md:flex-row space-x-0 md:space-x-16 space-y-16 md:space-y-0 justify-center overflow-hidden items-center mb-20" key={idx}>
                                    <div className=" flex-1 bg-gray-50">
                                        <img src={blog.image} alt="" className="w-full" />
                                    </div>
                                    <div className="flex justify-start items-start flex-col space-y-6 flex-1">
                                        <div className="flex flex-col space-y-6">
                                            <h1 className=" text-3xl font-bold dark:text-white font-poppins">
                                                {blog.title}
                                            </h1>
                                            <h1 className="text-xl font-normal text-taupe-gray">
                                                {blog.description}
                                            </h1>
                                            <a href={blog.url} target="_blank" className=" text-primary" rel="noreferrer">
                                                Read more on{" "}
                                                <span className="font-bold">Medium {">"}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                           
                        );
                    })}
                </div>
            </section>
        </>
    );
}
