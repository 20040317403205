import React, { useEffect, useState } from 'react';
import Preloader from './components/Loader/Preloader';
import Router from "./route/Router";

function App() {
  const [spinner, setSpinner] = useState(true);
  useEffect(() => {
    setTimeout(() => setSpinner(false), 3000)
  }, []);
  
  return spinner ? <Preloader /> : <Router />;

}

export default App;
