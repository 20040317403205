import { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import WorkContainer from "../container/Work/WorkContainer";

export default function Work() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [])
    return (
        <>
            <Header />
            <WorkContainer/>
            <Footer/>
        </>
    )
}