import { useParams } from "react-router-dom";
import ImageView from "../../../components/ImageVIew/ImageView";
import VideoView from "../../../components/VideoView/VideoView";
import { WorkData } from "../../../data/WorkData";


export default function Role() {

    let { project_id } = useParams();
    let data = null;
    if (WorkData[project_id]) data = WorkData[project_id].role;

    return (
        <>
            {data && <div id={data.id} className=" dark:text-white">
                <h1 className="text-2xl font-poppins font-semibold">{data.title}</h1>
                <div className="flex flex-col space-y-4 mt-3">
                    <table>
                        <tbody>
                            <tr>
                                <td className="inline-flex justify-start items-start">
                                    <span className="text-lg font-bold text-taupe-gray ">Role</span>
                                </td>

                                <td>
                                    <span className="">
                                        <span className="text-lg text-taupe-gray font-roboto">:</span>{" "}
                                        {data.role}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="inline-flex justify-start items-start">
                                    <span className="text-lg font-bold text-taupe-gray">Tools</span>
                                </td>
                                <td>
                                    <span className="">
                                        <span className="text-lg text-taupe-gray font-roboto">:</span>{" "}
                                        {data.tools}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="inline-flex justify-start items-start">
                                    <span className="text-lg font-bold text-taupe-gray">
                                        Tech stack{" "}
                                    </span>
                                </td>
                                <td>
                                    <span className="">
                                        <span className="text-lg text-taupe-gray font-roboto">:</span>{" "}
                                        {data.tech_stack}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td className="inline-flex justify-start items-start">
                                    <span className="text-lg font-bold text-taupe-gray">
                                        Timeline{" "}
                                    </span>
                                </td>
                                <td>
                                    <span className="">
                                        <span className="text-lg text-taupe-gray font-roboto">:</span>{" "}
                                        {data.timeline}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ImageView data={data} />
                    <VideoView data={data} />
                </div>
            </div>}
        </>
    );
}
