import { useEffect } from "react";
import AboutContainer from "../container/About/AboutContainer";

export default function About() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    },[])
    return (
        <>
            <AboutContainer/>
        </>
    )
}