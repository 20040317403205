import School from "./content/School";
import SkillSet from "./content/SkillSet";
import Testimonials from "./content/Testimonials";
import TimeLine from "./content/TimeLine";

export default function AboutContent() {
    return (
        <>
            <section className="dark:bg-gray-900 py-10">
                <div className="container">
                    <div className="flex flex-col space-y-16">
                        {/* skill set  */}
                        <SkillSet />
                        {/* timeline  */}
                        <TimeLine />
                        {/* school  */}
                        <School />
                      
                        
                    </div>
                </div>
            </section>
            <section className="dark:bg-gray-900 bg-gray-50 py-10">
                <div className="container">
                    {/* Testimonials  */}
                   <Testimonials/>
                </div>
            </section>
        </>
    );
}
