export const ProjectData = [
    {
        id:1,
        title: "Fintraffic",
        mode: " Predictive berth (ship) planning tool",
        image: "/assets/images/ship-planning.svg",
        feature: [
            "UX Research",
            "Interaction",
            "Visual design",
            "Prototype and Testing",
            "Dev colaboration",
            "Post launch UX research",
        ],
    },
    {
        id: 2,
        title: "Ontada Health",
        mode: "Aims to fight against cancer",
        image: "/assets/images/ontada/Mckesson.svg",
        feature: [
            "Scientific literature review",
            "Proposed form design best practices",
            "Prototype and Testing",
            "Visual design",
        ],
    },
    {
        id:3,
        title: "Newborn",
        mode: "iOS App, Baby Pictures",
        image: "/assets/images/newborn/newborn.png",
        feature: [
            "UX Research",
            "Interaction",
            "Visual design",
            "Prototype and Testing",
            "Dev colaboration",
            "Post launch UX research",
        ],
        
    },
    {
        id: 4,
        title: "Zapflow",
        mode: "Zapflow public website",
        image: "/assets/images/zapflow/zapflow.png",
        feature: [
            "Visual design",
            "Developement",
            "Testing",
        ],
    },
    {
        id: 5,
        title: "Photo Vault",
        mode: "iOS photo hider app",
        image: "/assets/images/photovault/photo-vault.png",
        feature: [
            "UX Research",
            "Interaction",
            "Visual design",
            "Testing",
            "Dev colaboration",
        ],
    },
    {
        id: 6,
        title: "BOLSE",
        mode: "Premium HTML5 web template",
        image: "/assets/images/bolse/bolse.png",
        feature: [
            "UX Research",
            "Interaction",
            "Visual design",
            "Testing",
            "Dev colaboration",
        ],
    },
    {
        id: 7,
        title: "HEX-CO",
        mode:  "The super fast color palettes generator!",
        image: "/assets/images/hexco/hex-co.png",
        feature: [
            "Visual design",
            "Developement",
            "Testing",
        ],
    },
    
];
