import HomeContainer from "../container/Home/HomeContainer";
import { useEffect } from "react";

export default function Home() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [])
    return (
        <>
            <HomeContainer/>
        </>
    )
}