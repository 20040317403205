import Assumptions from "./Assumptions";
import Buildingmvp from "./Buildingmvp";
import Clickableprototype from "./Clickableprototype";
import CollaborativeDesign from "./CollaborativeDesign";
import Common2 from "./Common_2";
import Common3 from "./Common_3";
import Common4 from "./Common_4";
import Common5 from "./Common_5";
import Dev from "./Dev";
import DevCollaboration from "./DevCollaboration";
import Experiment from "./Experiment";
import FeedbackAndResearch from "./FeedbackAndResearch";
import FigmaMouckups from "./FigmaMouckups";
import Ideation from "./Ideation";
import Interview from "./Interview";
import Common1 from "./PaperSketching";
import Post from "./Post";
import PostlaunchExperiment from "./PostlaunchExperiment";
import Process from "./Process";
import Research from "./Research";
import Runexperiment from "./Runexperiment";
import Sketch from "./Sketch";
import TestWithUsers from "./TestWithUsers";
import Wireframe from "./Wireframe";
import Wireframing from "./Wireframing";

export default function ProcessContent() {
    return (
        <>
            <section className="dark:bg-gray-900 py-4 bg-gray-50">
                <div className="container">
                    <div className="flex flex-col space-y-16">
                        {/* process section  */}
                        <Process />
                        {/* 1. Assumptions */}
                        <Assumptions />
                        {/* 2. Building an mvp */}
                        <Buildingmvp />
                        <CollaborativeDesign />
                        {/* 4. Paper sketching */}
                        <Common1 />
                        <Wireframing />
                        <TestWithUsers/>
                        <FigmaMouckups/>
                        <Clickableprototype />
                        {/* 3. Runexperiment */}
                        <Runexperiment />
                        {/* 4. Feedback and research */}
                        <FeedbackAndResearch />
                        {/* sketch section  */}
                        <Sketch />
                        <DevCollaboration />
                        <PostlaunchExperiment/>
                        {/* interview section  */}
                        <Interview />
                        {/* wireframe section  */}
                        <Wireframe />
                        {/* experiment section  */}
                        <Experiment />
                        {/* research section  */}
                        <Research />
                        {/* ideation section  */}
                        <Ideation />
                        {/* development section  */}
                        <Dev />
                        {/* post section  */}
                        <Post />
                        {/* common section  */}
                        <Common2 />
                        <Common3 />
                        <Common4 />
                        <Common5 />
                       
                    </div>
                </div>
            </section>
        </>
    );
}
