import { useEffect } from "react";
import BlogContainer from "../container/Blog/BlogContainer";

export default function Blog() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    })
  }, [])
    return (
        <>
          <BlogContainer/>
        </>
    )
}