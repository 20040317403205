export const WorkData = {
    1: {
        overview: {
            id: "overview",
            menu_name: "Overview",
            title: "Predictive berth planning tool for efficient port operation",
            description: "A predictive berth planning tool is like a smart helper for port operations. It's designed to make the best use of available berths and quays by giving you a clear picture of when ships are coming and going. This tool is like a wizard that helps port operators and planners schedule and manage their resources, making sure everything runs smoothly, reduce the fuel burn, minimize cost and ensure the carbon emission reduction.",
            image: "/assets/images/berthplanning/ship-planning-tool.svg",
            live_demo: "https://portactivity.fi/",
        },
        role: {
            id: "role",
            menu_name: "Role & timeline",
            title: "Role & timeline",
            role: "Transform idea into visualization, High fidelity proof of concept (prototype), Test with potential users, Dev collaboration, Ensure pixel perfect implementation, and Post launch UX research",
            tools: "Pen & Paper, Balsamiq, Figma",
            tech_stack: "React, Ant Design, Styled Component",
            timeline: "June , 2021 to Nov 2021 (4 months)",
            content: {
                images: [

                ],
                caption: "",
            },
            videos: [
             
            ]
        },
        understand: {
            id: "problem-statement",
            menu_name: "Problem Statement",
            title: "Problem Statement",
            description:
                "Imagine a situation where port operators and ship crews don't have an easy way to see important, up-to-the-minute information for planning ship activities at ports. Because of this, ship crews have to make lots of phone calls to check if a berth is available and other important details. This causes problems like delays and a lot of confusion. To solve this issue, we have introduce a new feature called the Predictive Berth Planning Tool. This tool will help everyone involved in port and ship operations by giving them a clear and easy-to-understand picture of all the information they need for smooth planning and operations. It's like a big, user-friendly map of everything happening at the port, making life easier for everyone involved.",
            content: {
                images: ["/assets/images/berthplanning/port-of-umeå.png"],
                caption: "Image by Author",
            },
            videos: [

            ]
        },
        user: {
            id: "user",
            menu_name: "User & target audience",
            title: "User & target audience",
            description:
                "Port stakeholders, agents, and vessel operators who are fully connected to the port and vessel operations.",
            content: {
                images: [

                ],
                caption: "",
            },
            videos: [

            ]
        },
        process: {
            id: "process",
            menu_name: "Process and what I did",
            title: "Process and what I did",
            description:
                "To make sure I really get what's going on, I take the time to look at every situation closely. I talk to the people involved and listen to their problems and the way they do things every day. I gather all the info I need so that I can get a clear understanding of current practices and problems associated with those. Once I've got all that info, I use the Lean UX method (Gothelf, 2014) as a plan. This method has four cycle. 1: Declare the Assumption, 2: Create MVP, 3: Run an Experiment, 4: Receive Feedback and Research. First, we make our best guesses about what we think will work (Declare the Assumption). Then, we create a basic version of what we're thinking(Create MVP).After that, we test it out to see if it works(Run an Experiment).In final part, we get feedback from the people who use it and do some research(Receive Feedback and Research). It was a iterative process which allowed us to go for continues trial and error and extract the best possible solutions.This way, we can make quick versions of our ideas and see if they're good before we spend a lot of time or money on various sectors such as development, project management and other essentials. It's like trying out a small sample of a recipe before cooking the whole meal.It saves us time and money and helps us make things that work better.",
            content: {
                images: ["/assets/images/lean-ux.svg"],
                caption: "Lean UX Methodology, image by Rahman, N. I & Rajanen (2019)",
            },
            videos: [

            ]
        },
        assumptions: {
            id: "assumptions",
            menu_name: "Declare the assumptions",
            title: "1. Declare the assumptions",
            description:
                "To bring our assumptions and requirements to life, we've made a list of user stories. To make our assumptions more tangible, we've woven them into the fabric of these stories, just like this:",
            content: {
                images: ["/assets/images/fintrafic-userstory.svg"],
                caption: "",
            },
            videos: [

            ]
        },
        buildingmvp: {
            id: "buildingmvp",
            menu_name: "Building an MVP",
            title: "2. Building an MVP",
            description:
                "I wanted to figure out if people would really use my product, so I came up with the Minimal Viable Product or MVP. It's like a simple version of the product that helps me learn about what customers like without making the whole thing. To make this MVP, I got my team together, and we all did some drawing on paper. We used the ideas we got from talking to the people who would use the product. Everyone on the team sketched their own ideas. Afterwards, we looked at all the sketeches and picked the best parts from each one.We took those good ideas and put them together into a final paper sketch.This way, we got a clear picture of what the product could be like without making the whole thing yet.It's like making a rough draft before writing the final version of a story. This saves time and helps us make something people will like.",
            content: {
                images: [],
                caption: "",
            },
            videos: [

            ]
        },
        collaborativedesign: {
            id: "CollaborativeDesign ",
            menu_name: "Collaborative design ",
            title: "Collaborative design",
            description:
                "We went for a team effort in our design journey, embracing the collaborative design process inspired by the Lean UX methodology. In this adventure, I was the UX enthusiast, alongside our talented developers, and our wise senior product manager. We even added a pinch of excitement by diving into a bit of competitor analysis, exploring products with a similar vibe. Together, we crafted our path to create something great.",
            content: {
                images: ["/assets/images/fintraffic-team-skills.svg"],
                caption: "Collaborative design team's skills where the high-performance skills present with deep color and fewer skills presented light blue color",
            },
        },
        papersketching: {
            id: "papersketching",
            menu_name: "Paper sketching",
            title: "Paper sketching",
            description:
                "All our team members were dedicated for a certain time to do brainstorm and prepare a paper sketch of their best preferable solutions. We took sufficient time for this sketching phase to be completed so that every one of us can come up with their individual best output. Afterwards, we accumulate all those outputs and combine them together for making one refined and efficient idea and made a final sketch.",
            content: {
                images: ["/assets/images/fintraffic-papersketching.png"],
                caption: "",
            },
        },
        wireframing: {
            id: "wireframing",
            menu_name: "Wireframing",
            title: "Wireframing",
            description:
                "We used Balsamiq tool for preparing a wire-frame that is clickable and interactive for the users. This version was tested by a few number of users and in house team to find the effectiveness of it. The findings of this test helped us to understand the painpoints, of users, effectiveness of our product, room of usabuility improvements and the direction of our design decision.",
            content: {
                images: [],
                caption: "",
            },
            videos: ["https://www.youtube.com/embed/VqzJAa2lLqw"
            ]
        },
        testwithusers: {
            id: "testwithusers",
            menu_name: "Test with potential users",
            title: " Early stage Testing with potential users",
            description:
                "After preparing the wireframe, I tested the prototype with the actual users and asking to perform some tasks to find the design directions.",
            content: {
                images: [],
                caption: "",
            },
            videos: [

            ]
        },
        figmamouckups: {
            id: "figmamouckups",
            menu_name: "Visual mouckups in figma",
            title: "Visual mouckups in figma",
            description:
                "We transform our initial wire frame into visual mock-ups which is updated and more lively version of our idea. All the crucial elements of our ideas was smoothly synthesized on this version so that we can create the most accurate prototype. We created a vessel list which is a versatile one, it can magically fill up with ships automatically through port calls or AIS(Automatic Identification System), or if our users prefer, they can sprinkle in some ships manually.It's all about giving you the flexibility and control you need.",
            content: {
                images: [],
                caption: "",
            },
            videos: [

            ]
        },
        clickableprototype: {
            id: "clickableprototype",
            menu_name: "Clickable prototype in figma",
            title: "Clickable prototype",
            description:
                "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility of the total capacity Vessel list is populated automatically via port calls or AIS or manually by users. Vessel list is populated automatically via port calls or AIS or manually by users.Vessel list is populated automatically via port",
            content: {
                images: [],
                caption: "",
            },
            videos: [
                "https://www.youtube.com/embed/P3V2MT2yznY",
            ]
        },
        runexperiment: {
            id: "runexperiment",
            menu_name: "Run experiment",
            title: "3. Run experiment",
            description: "In our project journey, we organized an interview session. We used the first version of our basic prototype created with Figma to help us. During the interview, we asked participants to complete tasks like reserving a maintenance space at the port and scheduling a port call, including specifying when a ship arrives and departs. This was enlightening as we carefully watched and noted any difficulties our users faced. We took notes on a notepad. We embarked on the path of improving our product through various changes with all those insights.",
            content: [
                "Setting up an interview",
                "Interview conducted with 1st version of MVP",
                "Ask for perform some task (How do you reserve a space for maintenance for the port)",
                "Observed pain points",
                "Note taking",
                "Iteration",
            ],
        },
        feedbackandresearch: {
            id: "feedbackandresearch",
            menu_name: "Feedback and research",
            title: "4. Feedback and research",
            description:
                "We analyzed the feedback provided by our users. Those played a crucial role in helping us understand how our product works. We noticed that they could easily use the product, like finding their way on a well-marked path. They also found it simple to keep track of when ships arrive and departures , which makes running the operations of the port smoother. Communication became more clear, like clean water after a storm, which reduced the gap in information between the people managing the port and the users. Our most exciting finding was, it can be expected that our product can ensure fewer delays for ships. This was greatly loved by the users and motivates us to keep making our product better, ensuring it's easy and efficient for all our users.",
            content: {
                images: ["/assets/images/berthplanning/run-experiment.png"],
                caption: "Run and experiment with potential user",
            },
            videos: [

            ]
        },
        sketch: {
            id: "finalui",
            menu_name: "Final UI",
            title: "Final UI",
            description:
                "",
            content: {
                images: [
                    "/assets/images/berthplanning/1.dashboard.png",
                    "/assets/images/berthplanning/2.add-new-vessel.png",
                    "/assets/images/berthplanning/3.vessels-added-for-planing.png",
                    "/assets/images/berthplanning/4.berth-plainning-modal.png",
                    "/assets/images/berthplanning/5.berth-plan-confirm.png",
                    "/assets/images/berthplanning/6.edit-berth-plan-modal.png",
                    "/assets/images/berthplanning/7.edit-berth-plan-confirm.png",
                    "/assets/images/berthplanning/8.edited-dashboard-on-hover.png",
                    "/assets/images/berthplanning/9.dashboard-on-hover.png",
                    "/assets/images/berthplanning/10.eta-and-pta-are-different.png",
                    "/assets/images/berthplanning/11.eta-and-pta-are-different-hover.png",
                ],
                caption: "Photo Caption",
            },
            videos: [

            ]
        },
        devcollaboration: {
            id: "devcollaboration",
            menu_name: "Dev collaboration",
            title: "Handoff to development",
            description:
                "For collaborating with development team, we made our big tasks smaller, just like breaking a big story into smaller chapters. Each task was like a special to-do list, and we assigned them to different team members, like picking different characters for our adventure. Together, we paid close attention to making our project look just right, like an artist adding the finishing touches to a painting. Every day, we worked together, like a close-knit group of friends, shaping our project with our different skills and ideas. It was this teamwork that made our project a rich and exciting story of creation.",
            content: [
                "Create the jira ticket",
                "Assign to teammate",
                "Contributes to development to ensure pixel perfect development",
                "Day to day collaboration with dev team",
            ],
        },
        postlaunchexperiment: {
            id: "postlaunchexperiment",
            menu_name: "Postlaunch Experiment",
            title: "Post-launch experiment",
            description:
                " After our project launch, we set out on a journey to make things better. We used a usability study like a map to understand what users thought. We found and fixed problems, like tidying up a messy room to make it neat and smooth. We worked together and shared our findings with the development team with our lessons and improvements. It was like a group of friends working on a story, and this helped our product get better and make users happy. ",
            content: [
                "Conduct interview",
                "Usability study",
                "Address bugs",
                "Put it onboard to dev team",
                "Iterate if needed",
            ],
        },

        outcome: {
            id: "outcome",
            menu_name: "Outcomes and lessons",
            title: "Outcomes and lessons",
            description:
                "In the arena of digital world, our mission is to be the bridge between our users' challenges and the solutions that simplify their lives. Users often present us with their problems, not neatly packaged solutions, because they trust us to be the innovators who transform their words into meaningful answers. It’s more likely to happen because what user says and what they do are different often. It's a bit like deciphering a craving without knowing the dish's name, piecing together the perfect recipe. Users' words and actions may not always align, as their true needs might only surface when they stumble upon something that truly resonates, akin to a buffet-goer who discovers their favorite dish. Our role is to navigate this journey, unlocking solutions through the unique narratives of our users, connecting their needs with the perfect design.",
            content: {
                images: [],
                caption: "",
            },
            videos: [

            ]
        },
        thanks: {
            id: "thanks",
            menu_name: "Thanks for reading",
            title: "Thanks for reading!",
            description:
                "",
            content: {
                images: [],
                caption: "",
            },
            videos: [

            ]
        },
       
    },
    2: {
        overview: {
            id: "overview",
            menu_name: "Overview",
            title: "Ontada Health",
            description: "Ontada is a part of McKesson Corporation and provides extensive network infrastructure for the healthcare industry, based in Texas, US",
            image: "/assets/images/ontada/mckesson-banner.svg",
            live_demo: "https://www.ontadahealth.com/",
        },
        role: {
            id: "role",
            menu_name: "Role & timeline",
            title: "Role & timeline",
            role: "Research, wireframing, UX, UI Design, Branding",
            tools: "Figma",
            timeline: "Feb 2017 - May 2017",
            content: {
                images: [
                ],
                caption: "",
            },
            videos: [

            ]
        },
        // process: {
        //     id: "process",
        //     menu_name: "Process and what I did",
        //     title: "Process and what I did",
        //     description:"",
        //     content: {
        //         images: ["/assets/images/newborn/newborn-ui-presentation.jpeg"],
        //         caption: "A comprehensive study of Form Design Best Practices",
        //     },
        //     videos: [

        //     ]
        // },

        outcome: {
            id: "outcome",
            menu_name: "Outcomes and lessons",
            title: "Outcomes and lessons",
            description:
                "We conducted a thorough evaluation of the existing form design by conducting a comprehensive literature review to explore scientific best practices and industry standards. Based on our findings, we have identified 17 best practices and developed a contrasting form layout that promises a significantly enhanced user experience.",
            content: {
                images: [],
                caption: "",
            },
            videos: [

            ]
        },
        thanks: {
            id: "thanks",
            menu_name: "Thanks for reading",
            title: "Thanks for reading",
            description:
                "You've reached the end of this case study! If you made it this far, thanks so much for reading through this case study! I'm very excited to share what my team and I have been working on the past year, and look forward to you trying these features live in the product for all of your hiring needs. Want to see more projects? check the more to explore section ",
            content: {
                images: [],
                caption: "",
            },
            videos: [

            ]
        },

    },

    3: {
        overview: {
            id: "overview",
            menu_name: "Overview",
            title: "New BORN",
            description:
                "Pregnancy Monthly Pictures Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
            image: "/assets/images/newborn/newborn-banner.png",
            live_demo: "https://apps.apple.com/us/app/baby-snaps-photo-editor-app/id1263470299",
        },
        role: {
            id: "role",
            menu_name: "Role & timeline",
            title: "Role & timeline",
            role: "Research, wireframing, UX, UI Design, Branding",
            tools: "Adobe XD, Illustrator, Invision",
            timeline: "Feb 2017 - May 2017",
            content: {
                images: [
                ],
                caption: "",
            },
            videos: [

            ]
        },
        process: {
            id: "process",
            menu_name: "Process and what I did",
            title: "Process and what I did",
            description:
                "",
            content: {
                images: ["/assets/images/newborn/newborn-ui-presentation.jpeg"],
                caption: "Photo Caption",
            },
            videos: [

            ]
        },

        outcome: {
            id: "outcome",
            menu_name: "Outcomes and lessons",
            title: "Outcomes and lessons",
            description:
                "In our first launch of the product, it was downloaded around 1M from the app store. Future implementation tent to create more doodles to allow the user to use in babies photo",
            content: {
                images: [],
                caption: "",
            },
            videos: [

            ]
        },
        thanks: {
            id: "thanks",
            menu_name: "Thanks for reading",
            title: "Thanks for reading",
            description:
                "You've reached the end of this case study! If you made it this far, thanks so much for reading through this case study! I'm very excited to share what my team and I have been working on the past year, and look forward to you trying these features live in the product for all of your hiring needs. Want to see more projects? check the more to explore section ",
            content: {
                images: [],
                caption: "",
            },
            videos: [

            ]
        },

    },

    4: {
        overview: {
            id: "overview",
            menu_name: "Overview",
            title: "Zapflow",
            description:
                "Zapflow public website. A secure front-office solution for investment professionals to efficiently manage deal flow, fundraising, portfolio monitoring and other operations in one place.",
            image: "/assets/images/zapflow/zapflow.png",
            live_demo: "https://www.zapflow.com/",
        },
        role: {
            id: "role",
            menu_name: "Role & timeline",
            title: "Role & timeline",
            role: "Research, wireframing, UX, UI Design, Branding",
            tools: "Adobe XD, Illustrator, Invision",
            tech_stack: " ",
            timeline: "Mar 2017 - Jul 2017, 4 months",
            content: {
                images: [
                ],
                caption: "",
            },
            videos: [

            ]
        },
        thanks: {
            id: "thanks",
            menu_name: "Thanks for reading",
            title: "Thanks for reading",
            description:
                "You've reached the end of this case study! If you made it this far, thanks so much for reading through this case study! I'm very excited to share what my team and I have been working on the past year, and look forward to you trying these features live in the product for all of your hiring needs. Want to see more projects? check the more to explore section ",
            content: {
                images: [],
                caption: "",
            },
            videos: [

            ]
        },

    },
    5: {
        overview: {
            id: "overview",
            menu_name: "Overview",
            title: "Photo Vault, iOS photo hider app ",
            description:
                "Photo vault is now named as photo hider. Hide Secret Picture App. Lock,Hide Secret Picture App Basic Features: - Simple interface, - Hide with Pin, - Touch id & face id, - Multiple Albums, - Custom album cover, - Integrated camera for taking photos directly",
            image: "/assets/images/photovault/photo-vault-banner.png",
            live_demo: "https://apps.apple.com/us/app/photo-hider/id1447361898",
        },
        role: {
            id: "role",
            menu_name: "Role & timeline",
            title: "Role & timeline",
            role: "Research, wireframing, UX, UI Design, Branding",
            tools: "Adobe XD, Illustrator, Invision",
            tech_stack: " ",
            timeline: "Mar 2017 - Jul 2017, 4 months",
            content: {
                images: [
                ],
                caption: "",
            },
            videos: [

            ]
        },
        // understand: {
        //     id: "understand",
        //     menu_name: "Understanding The Challenge",
        //     title: "Understanding The Challenge",
        //     description:
        //         "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility of the total capacity Vessel list is populated automatically via port calls or AIS or manually by users. Vessel list is populated automatically via port calls or AIS or manually by users.Vessel list is populated automatically via port",
        //     content: {
        //         images: [
        //             "/assets/images/man.png",
        //             "/assets/images/man.png",
        //             "/assets/images/man.png",
        //         ],
        //         caption: "Photo Caption",
        //     },
        //     videos: [

        //     ]
        // },
        // user: {
        //     id: "user",
        //     menu_name: "User & target audience",
        //     title: "User & target audience",
        //     description:
        //         "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility of the total capacity Vessel list is populated automatically via port calls or AIS or manually by users. Vessel list is populated automatically via port calls or AIS or manually by users.Vessel list is populated automatically via port",
        //     content: {
        //         images: [
        //             "/assets/images/man.png",
        //             "/assets/images/man.png",
        //             "/assets/images/man.png",
        //         ],
        //         caption: "Photo Caption",
        //     },
        //     videos: [

        //     ]
        // },
        // scope: {
        //     id: "scope",
        //     menu_name: "Scope and constraints",
        //     title: "Scope and constraints",
        //     description:
        //         "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility of the total capacity Vessel list is populated automatically via port calls or AIS or manually by users. Vessel list is populated automatically via port calls or AIS or manually by users.Vessel list is populated automatically via port",
        //     content: {
        //         images: [
        //             "/assets/images/man.png",
        //             "/assets/images/man.png",
        //             "/assets/images/man.png",
        //         ],
        //         caption: "Photo Caption",
        //     },
        //     videos: [

        //     ]
        // },
        process: {
            id: "process",
            menu_name: "Process and what I did",
            title: "Process and what I did",
            description:
                "UI DESIGN",
            content: {
                images: ["/assets/images/photovault/photo-vault-presentation.png"],
                caption: "Photo ui projection",
            },
            videos: [

            ]
        },
        // interview: {
        //     id: "interview",
        //     menu_name: "Interview",
        //     title: "Interview",
        //     description:
        //         "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility of the total capacity Vessel list is populated automatically via port calls or AIS or manually by users. Vessel list is populated automatically via port calls or AIS or manually by users.Vessel list is populated automatically via port",
        //     content: {
        //         images: ["/assets/images/image39.png", "/assets/images/man.png"],
        //         caption: "Photo Caption",
        //     },
        //     videos: [

        //     ]
        // },
        // sketch: {
        //     id: "sketch",
        //     menu_name: "Sketch",
        //     title: "Sketch",
        //     description:
        //         "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility of the total capacity Vessel list is populated automatically via port calls or AIS or manually by users. Vessel list is populated automatically via port calls or AIS or manually by users.Vessel list is populated automatically via port",
        //     content: {
        //         images: [
        //             "/assets/images/image43.png",
        //             "/assets/images/image43.png",
        //             "/assets/images/image43.png",
        //             "/assets/images/image43.png",
        //             "/assets/images/image43.png",
        //         ],
        //         caption: "Photo Caption",
        //     },
        //     videos: [

        //     ]
        // },
        // wireframe: {
        //     id: "wireframe",
        //     menu_name: "Wireframe",
        //     title: "Wireframe",
        //     description:
        //         "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility of the total capacity Vessel list is populated automatically via port calls or AIS or manually by users. Vessel list is populated automatically via port calls or AIS or manually by users.Vessel list is populated automatically via port",
        //     content: {
        //         images: ["/assets/images/image47.png"],
        //         caption: "Photo Caption",
        //     },
        //     videos: [

        //     ]
        // },
        // experiment: {
        //     id: "experiment",
        //     menu_name: "Experiment",
        //     title: "Experiment",
        //     description:
        //         "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility of the total capacity Vessel list is populated automatically via port calls or AIS or manually by users. Vessel list is populated automatically via port calls or AIS or manually by users.Vessel list is populated automatically via port",
        //     content: {
        //         images: ["/assets/images/image39.png"],
        //         caption: "Photo Caption",
        //     },
        //     videos: [

        //     ]
        // },
        // research: {
        //     id: "research",
        //     menu_name: "Research and feedback",
        //     title: "Research and feedback",
        //     description:
        //         "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility of the total capacity Vessel list is populated automatically via port calls or AIS or manually by users. Vessel list is populated automatically via port calls or AIS or manually by users.Vessel list is populated automatically via port",
        //     content: [
        //         "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility of the total capacity",
        //         "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility of the total capacity",
        //     ],
        // },
        // ideation: {
        //     id: "ideation",
        //     menu_name: "Ideation and Final design",
        //     title: "Ideation and Final design",
        //     description:
        //         "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility of the total capacity Vessel list is populated automatically via port calls or AIS or manually by users. Vessel list is populated automatically via port calls or AIS or manually by users.Vessel list is populated automatically via port",
        //     content: {
        //         images: ["/assets/images/image39.png"],
        //         caption: "Photo Caption",
        //     },
        //     videos: [

        //     ]
        // },
        // dev: {
        //     id: "dev",
        //     menu_name: "Dev colaboration",
        //     title: "Dev colaboration",
        //     description:
        //         "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility of the total capacity Vessel list is populated automatically via port calls or AIS or manually by users. Vessel list is populated automatically via port calls or AIS or manually by users.Vessel list is populated automatically via port",
        //     content: {
        //         images: ["/assets/images/image39.png"],
        //         caption: "Photo Caption",
        //     },
        //     videos: [

        //     ]
        // },

        // post: {
        //     id: "post",
        //     menu_name: "Post launch experiment",
        //     title: "Post launch experiment",
        //     description:
        //         "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility of the total capacity Vessel list is populated automatically via port calls or AIS or manually by users. Vessel list is populated automatically via port calls or AIS or manually by users.Vessel list is populated automatically via port",
        //     content: {
        //         images: ["/assets/images/image39.png"],
        //         caption: "Photo Caption",
        //     },
        //     videos: [

        //     ]
        // },

        // common: {
        //     id: "common",
        //     menu_name: "Common for reading",
        //     title: "Common for reading",
        //     description:
        //         "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility of the total capacity Vessel list is populated automatically via port calls or AIS or manually by users. Vessel list is populated automatically via port calls or AIS or manually by users.Vessel list is populated automatically via port",
        //     content: {
        //         images: [],
        //         caption: "",
        //     },
        //     videos: [

        //     ]
        // },
        // common1: {
        //     id: "common1",
        //     menu_name: "Common for reading1",
        //     title: "Common for reading1",
        //     description:
        //         "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility of the total capacity Vessel list is populated automatically via port calls or AIS or manually by users. Vessel list is populated automatically via port calls or AIS or manually by users.Vessel list is populated automatically via port",
        //     content: {
        //         images: [],
        //         caption: "",
        //     },
        //     videos: [

        //     ]
        // },
        // common2: {
        //     id: "common2",
        //     menu_name: "Common for reading2",
        //     title: "Common for reading2",
        //     description:
        //         "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility of the total capacity Vessel list is populated automatically via port calls or AIS or manually by users. Vessel list is populated automatically via port calls or AIS or manually by users.Vessel list is populated automatically via port",
        //     content: {
        //         images: [],
        //         caption: "",
        //     },
        //     videos: [

        //     ]
        // },
        // common3: {
        //     id: "common3",
        //     menu_name: "Common for reading3",
        //     title: "Common for reading3",
        //     description:
        //         "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility of the total capacity Vessel list is populated automatically via port calls or AIS or manually by users. Vessel list is populated automatically via port calls or AIS or manually by users.Vessel list is populated automatically via port",
        //     content: {
        //         images: [],
        //         caption: "",
        //     },
        //     videos: [

        //     ]
        // },
        // common4: {
        //     id: "common4",
        //     menu_name: "Common for reading4",
        //     title: "Common for reading4",
        //     description:
        //         "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility of the total capacity Vessel list is populated automatically via port calls or AIS or manually by users. Vessel list is populated automatically via port calls or AIS or manually by users.Vessel list is populated automatically via port",
        //     content: {
        //         images: [],
        //         caption: "",
        //     },
        //     videos: [

        //     ]
        // },
        // common5: {
        //     id: "common5",
        //     menu_name: "Common for reading5",
        //     title: "Common for reading5",
        //     description:
        //         "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility of the total capacity Vessel list is populated automatically via port calls or AIS or manually by users. Vessel list is populated automatically via port calls or AIS or manually by users.Vessel list is populated automatically via port",
        //     content: {
        //         images: [],
        //         caption: "",
        //     },
        //     videos: [

        //     ]
        // },

        // outcome: {
        //     id: "outcome",
        //     menu_name: "Outcomes and lessons",
        //     title: "Outcomes and lessons",
        //     description:
        //         "Plan your port operations several weeks ahead Locate vessels to berths and bollards (position & direction) for better visibility of the total capacity Vessel list is populated automatically via port calls or AIS or manually by users. Vessel list is populated automatically via port calls or AIS or manually by users.Vessel list is populated automatically via port",
        //     content: {
        //         images: [],
        //         caption: "",
        //     },
        //     videos: [

        //     ]
        // },
        thanks: {
            id: "thanks",
            menu_name: "Thanks for reading",
            title: "Thanks for reading",
            description:
                "You've reached the end of this case study! If you made it this far, thanks so much for reading through this case study! I'm very excited to share what my team and I have been working on the past year, and look forward to you trying these features live in the product for all of your hiring needs. Want to see more projects? check the more to explore section ",
            content: {
                images: [],
                caption: "",
            },
            videos: [

            ]
        },

    },
    6: {
        overview: {
            id: "overview",
            menu_name: "Overview",
            title: "Bolse premium HTML 5 template ",
            description:
                "Bolse premium HTML 5 template",
            image: "/assets/images/bolse/bolse.png",
            live_demo: "https://atikrhaman.github.io/bolse-html-premium-template/",
        },
        role: {
            id: "role",
            menu_name: "Role & timeline",
            title: "Role & timeline",
            role: "Research, wireframing, UX, UI Design, Branding",
            tools: "Adobe XD, Illustrator, Invision",
            tech_stack: " ",
            timeline: "Mar 2017 - Jul 2017, 4 months",
            content: {
                images: [
                ],
                caption: "",
            },
            videos: [

            ]
        },
        thanks: {
            id: "thanks",
            menu_name: "Thanks for reading",
            title: "Thanks for reading",
            description:
                "You've reached the end of this case study! If you made it this far, thanks so much for reading through this case study! I'm very excited to share what my team and I have been working on the past year, and look forward to you trying these features live in the product for all of your hiring needs. Want to see more projects? check the more to explore section ",
            content: {
                images: [],
                caption: "",
            },
            videos: [

            ]
        },

    },
    7: {
        overview: {
            id: "overview",
            menu_name: "Overview",
            title: "HEX-CO",
            description:
                "The super fast color palettes generator!",
            image: "/assets/images/hexco/hex-co.png",
            live_demo: "https://atikrhaman.github.io/hexa-color-genarator-2/",
        },
        role: {
            id: "role",
            menu_name: "Role & timeline",
            title: "Role & timeline",
            role: "Research, wireframing, UX, UI Design, Branding",
            tools: "Adobe XD, Illustrator, Invision",
            tech_stack: " ",
            timeline: "Mar 2017 - Jul 2017, 4 months",
            content: {
                images: [
                ],
                caption: "",
            },
            videos: [

            ]
        },
        thanks: {
            id: "thanks",
            menu_name: "Thanks for reading",
            title: "Thanks for reading",
            description:
                "You've reached the end of this case study! If you made it this far, thanks so much for reading through this case study! I'm very excited to share what my team and I have been working on the past year, and look forward to you trying these features live in the product for all of your hiring needs. Want to see more projects? check the more to explore section ",
            content: {
                images: [],
                caption: "",
            },
            videos: [

            ]
        },

    },
  
};
