/* eslint-disable jsx-a11y/anchor-is-valid */
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import { BlogData } from "../../data/BlogData";

const options = {
    margin: 30,
    autoplayHoverPause: true,
    responsiveClass: true,
    autoplay: false,
    nav: true,
    dots: true,
    loop:true,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        550: {
            items: 2,
        },
        600: {
            items: 2,
        },
        700: {
            items: 2,
        },
        1200: {
            items: 3,
        },
    },
};

export default function BlogSection() {
    return (
        <>
            <section
                id="sliderid"
                className="relative py-5 xl:py-10 dark:bg-gray-900 bg-gray-50"
            >
                <div className="container flex flex-col space-y-14">
                    <div className="flex flex-col space-y-4 dark:text-white">
                        <h1 className=" text-2xl font-poppins">Blogs</h1>
                        <h1 className="text-lg">
                            I did some cool stuff when I was a Computer Science & Engineering student. From machine learning to web dev, these projects shaped my understanding of the technical world. I can’t count how many times my technical background made me a versatile team player. I can’t count how See all blogs    <Link to="/blog" className="text-primary font-bold">See all blogs</Link>
                        </h1>
                    </div>
                    <OwlCarousel
                        className="owl-theme owl-custom"
                        navText={[
                            '<i class="gg-chevron-left"></i>',
                            '<i class="gg-chevron-right"></i>',
                        ]}
                        {...options}
                        id="blogslide"
                    >
                        {BlogData.map((blog, idx) => {
                            // console.log(blog.title.length);
                            return (
                              
                                    <div className="flex flex-col item bg-white dark:bg-gray-700 shadow my-2 min-h-[550px]" key={idx}>
                                        <div className="inline-flex flex-1">
                                            <img src={blog.image} alt="" />
                                       </div>
                                        <div className="p-6 flex flex-col space-y-4 flex-1">
                                        <h1 className="text-xl font-bold dark:text-white font-poppins" title={blog.title}>
                                            {blog.title.length > 56 ? blog.title.substring(0, 55).concat("...") : blog.title}
                                            </h1>
                                            <h1 className="text-base text-taupe-gray">
                                            {/* {blog.description} */}
                                            {blog.description.length > 95 ? blog.description.substring(0, 95).concat("...") : blog.description}
                                            </h1>
                                            <a href={blog.url} target="_blank" className=" text-primary" rel="noreferrer">
                                                Read more on{" "}
                                                <span className="font-bold">Medium {">"}</span>
                                            </a>
                                        </div>
                                    </div>
                               
                            );
                        })}
                    </OwlCarousel>
                  
                </div>
            </section>
        </>
    );
}
