import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { useTheme } from "../../context/ThemeContext";

export default function DarkSwitch() {
    // const [open, setOpen] = useState(false);

    const { theme, setTheme } = useTheme();
    // theme switch
    function switchTheme() {
        if (theme === "light") {
            setTheme("dark");
        } else {
            setTheme("light");
        }
    }
    return (
        <>
            <div>
                <div className="fixed right-5 bottom-3 z-[100]">
                    <button onClick={switchTheme}>
                        {theme === "dark" ? (
                            <RiSunFill className="w-6 h-6 transition-all duration-300 hover:text-black  dark:text-white hover:dark:text-white text-primary" />
                        ) : (
                            <RiMoonFill className="w-6 h-6 transition-all duration-300 hover:text-black dark:text-white hover:dark:text-white text-primary " />
                        )}
                    </button>
                </div>
            </div>
        </>
    )
}