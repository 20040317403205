import { useEffect, useState } from "react";

export function useScrollPosition() {
    let [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        let updateScrollPositions = () => {
            setScrollPosition(window.pageYOffset);
        }
        window.addEventListener('scroll', updateScrollPositions);

        updateScrollPositions();

        return () => {
            window.removeEventListener('scroll', updateScrollPositions)
        }
    }, []);


    return scrollPosition;
}