import { useParams } from "react-router-dom";
import { WorkData } from "../../data/WorkData";
import ProcessContent from "./content/Process/ProcessContent";
import VerticalMenu from "./VerticalMenu";
import WorkContentDown from "./WorkContentDown";
import WorkContentUp from "./WorkContentUp";
import WorkHero from "./WorkHero";

export default function WorkContainer() {
    let { project_id } = useParams();
    let data = null;
    if (WorkData[project_id]) data = WorkData[project_id];
    return (
        <>
            {data ? (
                <>
                    {/* work hero section  */}
                    <WorkHero />
                    {/* work up conent  */}
                    <WorkContentUp />
                    {/* process section and inside nested section include  */}
                    <ProcessContent />
                    {/* work down content  */}
                    <WorkContentDown />
                    {/* vertical menu  */}
                    <VerticalMenu />
                </>
            ) : (
                <>
                    {" "}
                    <section class="col-span-6 mb-10">
                        <div class="container w-7xl mx-auto">
                            <div class="mx-[.7rem] md:mx-[1rem] lg:mx-[2rem] xl:mx-[4rem] 2xl:mx-[10rem]"></div>
                            <div class="flex flex-col justify-center items-center">
                                <div>
                                    <img
                                            src="/assets/images/not-found.svg"
                                        alt=""
                                    />
                                </div>
                                {/* <h1 class="text-7xl font-bold">404</h1>
                                <h1 class="text-3xl md:text-4xl lg:text-6xl font-bold text-center">
                                    Not found!
                                </h1> */}
                            </div>
                        </div>
                    </section>
                </>
            )}
        </>
    );
}
