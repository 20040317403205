import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import { ProjectData } from "../../../data/ProjectData";

const options = {
    margin: 30,
    autoplayHoverPause: true,
    responsiveClass: true,
    autoplay: false,
    nav: true,
    dots: true,
    loop: true,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        550: {
            items: 2,
        },
        600: {
            items: 2,
        },
        700: {
            items: 2,
        },
        1200: {
            items: 3,
        },
    },
};


export default function Explore() {
    // console.log(window.location.origin);
    return (
        <div>

            <div className="flex flex-col space-y-4 dark:text-white">
                <h1 className=" text-2xl">More to explore</h1>
            </div>
            <OwlCarousel
                className="owl-theme owl-explore"
                navText={[
                    '<i class="gg-chevron-left"></i>',
                    '<i class="gg-chevron-right"></i>',
                ]}
                {...options}
                id="explore"
            >
                {ProjectData.map((project, idx) => {
                    return (
                        <button onClick={() => {
                            window.location.assign(`${project.id}`);
                        }} key={idx}>
                            <div className="flex item justify-center items-center bg-white dark:bg-gray-700 shadow my-2" key={idx}>
                                <div className="flex-1">
                                    <img src={project.image} alt="" className="w-full h-full object-cover" />
                                </div>
                                <div className="p-6 flex-1 flex flex-col space-y-1">
                                    <h1 className="text-lg font-bold dark:text-white font-poppins">
                                        {/* {project.title} */}
                                        {project.title.length > 7 ? project.title.substring(0, 7).concat("...") : project.title}
                                    </h1>
                                    <h1 className="text-base text-taupe-gray">
                                        {project.mode}
                                    </h1>
                                </div>
                            </div>
                        </button>

                    );
                })}
            </OwlCarousel>

        </div>
    )
}