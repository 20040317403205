/* eslint-disable jsx-a11y/anchor-is-valid */

import { Dialog, Transition } from "@headlessui/react";
import React, { useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { FaBehance, FaGithub, FaLinkedinIn, FaMediumM } from "react-icons/fa";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { Link, useLocation, useParams } from "react-router-dom";
// import { useTheme } from "../../context/ThemeContext";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import { ReactComponent as ReactLogo } from '../../Logo.svg';

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Header() {
    const router = useLocation();
    const [open, setOpen] = useState(false);

    // const { theme, setTheme } = useTheme();
    // theme switch
    // function switchTheme() {
    //     if (theme === "light") {
    //         setTheme("dark");
    //     } else {
    //         setTheme("light");
    //     }
    // }

    const scrollposition = useScrollPosition();

    let {project_id}=useParams()

    return (
        <>
            <Transition.Root show={open}>
                <Dialog
                    as="div"
                    className="fixed inset-0 flex z-[1001] lg:hidden"
                    onClose={setOpen}
                >
                    <Transition.Child
                        as={React.Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>
                    <Transition.Child
                        as={React.Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="bg-white w-10/12 h-full max-w-xs absolute left-0 p-3 dark:bg-gray-900">
                            <div className="flex justify-end items-center py-2 border-b border-1 border-gray-200">
                                <button
                                    onClick={() => setOpen(false)}
                                    className="transition-all duration-300 hover:text-primary dark:text-white"
                                >
                                    <IoMdClose className="w-5 h-5" />
                                </button>
                            </div>
                            <div className="mt-5">
                                {/* menu start  */}
                                <div className="flex flex-col justify-start items-start space-y-5 text-[1.12rem] font-bold uppercase font-poppins text-gray-700 dark:text-white">
                                    {/* menu  */}
                                    <p
                                        className={classNames(
                                            router.pathname === "/"
                                                ? "text-primary border-b-2 border-primary"
                                                : "relative after:absolute after:bottom-0 after:left-0 after:w-0 after:transition-all after:duration-300 hover:after:w-full after:border-b-2 after:border-primary transition-all duration-300 hover:text-primary active:text-primary",
                                            ""
                                        )}
                                    >
                                        <Link to="/" >
                                            Work
                                        </Link>
                                    </p>

                                    <p
                                        className={classNames(
                                            router.pathname === "/about-me"
                                                ? "text-primary border-b-2 border-primary"
                                                : "relative after:absolute after:bottom-0 after:left-0 after:w-0 after:transition-all after:duration-300 hover:after:w-full after:border-b-2 after:border-primary transition-all duration-300 hover:text-primary active:text-primary",
                                            ""
                                        )}
                                    >
                                        <Link to="/about-me" >
                                            About
                                        </Link>
                                    </p>
                                    <p
                                        className={classNames(
                                            router.pathname === "/blog"
                                                ? "text-primary border-b-2 border-primary"
                                                : "relative after:absolute after:bottom-0 after:left-0 after:w-0 after:transition-all after:duration-300 hover:after:w-full after:border-b-2 after:border-primary transition-all duration-300 hover:text-primary active:text-primary",
                                            ""
                                        )}
                                    >
                                        <Link to="/blog">
                                            Blog
                                        </Link>
                                    </p>
                                    
                                </div>
                                <div className="md:hidden mt-4 flex space-x-4 ">
                                    <a href="https://github.com/AtikRhaman" target="_blank" rel="noreferrer">
                                        <FaGithub className="w-5 h-5 transition-all duration-300 hover:text-primary dark:text-white hover:dark:text-primary" />
                                    </a>
                                    <a href="https://www.linkedin.com/in/rahmanatikur/" target="_blank" rel="noreferrer">
                                        <FaLinkedinIn className="w-5 h-5 transition-all duration-300 hover:text-primary dark:text-white hover:dark:text-primary" />
                                    </a>
                                    <a href="https://medium.com/@uxatik" target="_blank" rel="noreferrer">
                                        <FaMediumM className="w-5 h-5 transition-all duration-300 hover:text-primary dark:text-white hover:dark:text-primary" />
                                    </a>
                                    <a href="https://www.behance.net/uxatik" target="_blank" rel="noreferrer">
                                        <FaBehance className="w-5 h-5 transition-all duration-300 hover:text-primary dark:text-white hover:dark:text-primary" />
                                    </a>
                                </div>
                                {/* menu end  */}
                            </div>
                        </div>
                    </Transition.Child>
                </Dialog>
            </Transition.Root>

            {/* pc version  */}
            <nav
                className={classNames(
                    scrollposition === 0
                        ? "shadow-none"
                        : "shadow dark:shadow-gray-700 sticky top-0 w-full flex",
                    "dark:bg-gray-900 mx-auto bg-white z-[1000] overflow-hidden relative"
                )}
            >
                <div className={classNames(
                    scrollposition === 0 ? " absolute left-5 top-[3.2rem]" : " absolute left-5 top-[1.45rem]","transition-all duration-300 hidden lg:block"
                )}>
                    {project_id && <Link to={-1}><HiOutlineArrowLeft className="w-7 h-7 text-primary" /></Link>}
                </div>
                <div
                    className={classNames(
                        scrollposition === 0 ? " container pt-10 pb-10" : "container py-3",
                        "transition-all duration-[300ms]"
                    )}
                >
                    <div className="flex justify-between items-center ">
                        <div className="flex justify-center items-center space-x-28">
                            <div className={classNames(project_id ? "flex space-x-16 justify-center items-center" : "", "")}>
                                {/* <div>
                                    {project_id && <Link to={-1}><HiOutlineArrowLeft className="w-7 h-7 text-primary" /></Link>}
                                </div> */}
                                {/* <Link to="/">
                                    <h1 className="inline-flex flex-col text-lg leading-5 uppercase bg-primary p-2 rounded-md justify-center items-center space-y-0">
                                        <span>Ux</span>
                                        <span className="text-white font-bold">Atik</span>
                                    </h1>
                                </Link> */}
                                <Link to="/">
                                    <ReactLogo />
                                </Link>
                                {/* <div className={classNames(project_id ? " flex space-x-6 justify-between items-center":"")}>
                                    <div>
                                        {project_id && <Link to={-1}><HiOutlineArrowLeft className="w-7 h-7 text-primary" /></Link>}
                                    </div>
                                    <Link to="/">
                                        <ReactLogo />
                                    </Link>
                                </div> */}
                            </div>
                            <div className="hidden md:flex space-x-14 text-[1.12rem]">
                                <p
                                    className={classNames(
                                        router.pathname === "/"
                                            ? "text-primary border-b-2 border-primary"
                                            : "relative after:absolute after:bottom-0 after:left-0 after:w-0 after:transition-all after:duration-300 hover:after:w-full after:border-b-2 after:border-primary transition-all duration-300 hover:text-primary active:text-primary  dark:text-white hover:dark:text-primary",
                                        ""
                                    )}
                                >
                                    <Link to="/" >
                                        Work
                                    </Link>
                                </p>

                                <p
                                    className={classNames(
                                        router.pathname === "/about-me"
                                            ? "text-primary border-b-2 border-primary"
                                            : "relative after:absolute after:bottom-0 after:left-0 after:w-0 after:transition-all after:duration-300 hover:after:w-full after:border-b-2 after:border-primary transition-all duration-300 hover:text-primary active:text-primary dark:text-white hover:dark:text-primary",
                                        ""
                                    )}
                                >
                                    <Link to="/about-me" >
                                        About
                                    </Link>
                                </p>
                                <p
                                    className={classNames(
                                        router.pathname === "/blog"
                                            ? "text-primary border-b-2 border-primary"
                                            : "relative after:absolute after:bottom-0 after:left-0 after:w-0 after:transition-all after:duration-300 hover:after:w-full after:border-b-2 after:border-primary transition-all duration-300 hover:text-primary active:text-primary  dark:text-white hover:dark:text-primary",
                                        ""
                                    )}
                                >
                                    <Link to="/blog" >
                                        Blog
                                    </Link>
                                </p>

                                {/* <div className="flex justify-center items-center space-x-4">
                                    <button onClick={switchTheme}>
                                        {theme === "dark" ? (
                                            <RiSunFill className="w-5 h-5 transition-all duration-300 hover:text-primary  dark:text-white hover:dark:text-primary" />
                                        ) : (
                                                <RiMoonFill className="w-5 h-5 transition-all duration-300 hover:text-primary dark:text-white hover:dark:text-primary" />
                                        )}
                                    </button>
                                </div> */}
                            </div>
                        </div>
                        <div className="hidden md:flex space-x-10 ">
                            <a href="https://github.com/AtikRhaman" target="_blank" rel="noreferrer">
                                <FaGithub className="w-5 h-5 transition-all duration-300 hover:text-primary dark:text-white hover:dark:text-primary" />
                            </a>
                            <a href="https://www.linkedin.com/in/rahmanatikur/" target="_blank" rel="noreferrer">
                                <FaLinkedinIn className="w-5 h-5 transition-all duration-300 hover:text-primary dark:text-white hover:dark:text-primary" />
                            </a>
                            <a href="https://medium.com/@uxatik" target="_blank" rel="noreferrer">
                                <FaMediumM className="w-5 h-5 transition-all duration-300 hover:text-primary dark:text-white hover:dark:text-primary" />
                            </a>
                            <a href="https://www.behance.net/uxatik" target="_blank" rel="noreferrer">
                                <FaBehance className="w-5 h-5 transition-all duration-300 hover:text-primary dark:text-white hover:dark:text-primary" />
                            </a>
                        </div>
                        <div className="flex md:hidden justify-center items-center space-x-4">
                            {/* <button onClick={switchTheme}>
                                {theme === "dark" ? (
                                    <RiSunFill className="w-6 h-6 transition-all duration-300 hover:text-primary dark:text-white" />
                                ) : (
                                        <RiMoonFill className="w-6 h-6 transition-all duration-300 hover:text-primary dark:text-white" />
                                )}
                            </button> */}

                            <button onClick={() => setOpen(true)}>
                                <AiOutlineMenu className="w-6 h-6 transition-all duration-300 hover:text-primary dark:text-white" />
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}
