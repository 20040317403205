export default function SchoolItems({ items }) {
    return (
        <>
            {items.map((item, idx) => {
                return (
                 
                        <div className="flex space-x-4 justify-start items-start before:w-[2px] before:h-full before:absolute relative before:bg-light-silver after:w-3 after:h-3 after:bg-primary after:absolute after:left-[-5px] after:top-2 before:top-4 pb-7" key={idx}>
                            {/* <div className="mt-2"> */}
                                {/* <p className="w-3 h-3 bg-primary"></p>
                                <p className="w-1 h-[130px] ml-1 bg-primary"></p> */}
                            {/* </div> */}
                            <div className="flex flex-col space-y-5 ml-4">
                                <div className="flex flex-col space-y-1">
                                    <h1 className="text-2xl">{item.varsity}</h1>
                                    <div className="flex flex-col text-quartz">
                                        <span>
                                            <span className=" text-[20px]">{item.degree_name}</span>
                                            <span> - </span>
                                            <span>{item.degree}</span>
                                        </span>
                                        <span>{item.date}</span>
                                    </div>
                                </div>
                                <p className="text-lg">{item.description}</p>
                            </div>
                        </div>
               
                );
            })}
        </>
    );
}
