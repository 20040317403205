import FsLightbox from 'fslightbox-react';
import { useState } from "react";
import LazyLoad from 'react-lazyload';
export default function ImageView({ data }) {

    const [toggler, setToggler] = useState(false);
    return (
        <>
            <div className="flex flex-wrap justify-start items-center m-0 place-content-stretch">
                {data && data.content && data.content.images && data.content.images.map((item, idx) => {
                    return (
                        <div key={idx}>
                            <LazyLoad height={200}>
                            <button onClick={() => setToggler(!toggler)} className="mr-4">
                                <img
                                    src={item}
                                    alt={data.title}
                                    key={idx}
                                    className="flex-1"
                                />
                           </button>
                            </LazyLoad>
                         
                        </div> 
                    );
                })}
                <FsLightbox
                    toggler={toggler}
                    sources={data.content.images}
                    key={data.content.images.length}
                />
            </div>
            {/* <FsLightbox
                toggler={toggler}
                sources={data.content.images}
                key={data.content.images.length}
            /> */}
            <h1 className="inline-flex justify-center items-center text-[0.8rem] text-taupe-gray">
                {data.content.caption}
            </h1>
        </>
    )
}