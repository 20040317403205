import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import BlogHero from "./BlogHero";
import BlogItems from "./BlogItems";

export default function BlogContainer() {
    return (
        <>
            {/* Header  */}
            <Header />
            {/* Blog Hero  */}
            <BlogHero />
            {/* Blogs Items  */}
            <BlogItems />
            {/* Footer  */}
            <Footer />
        </>
    );
}
