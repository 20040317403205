import Explore from "./content/Explore";
import Outcome from "./content/Outcome";
import Thanks from "./content/Thanks";

export default function WorkContentDown() {
    return (
        <>
            <section className="dark:bg-gray-900 py-10">
                <div className="container">
                    <div className="flex flex-col space-y-16">
                        {/* outcome section  */}
                        <Outcome />
                        {/* thanks for reading section  */}
                        <Thanks />
                        {/* more explore  */}
                        <Explore/>
                    </div>
                </div>
            </section>
        </>
    );
}
