export const BlogData = [
    {
        title: "What is Lean UX? Foundation of Lean UX and its 15 princ lasdh asldfo asdiow ",
        description:
            "Many people asked me about UX, Lean UX, and software development. How the software is designed?",
        image: "/assets/images/lean.png",
        url: "https://medium.com/design-bootcamp/what-is-lean-ux-foundation-of-lean-ux-and-its-principles-75686ab5e09f",
    },
    {
        title: "Lean UX Design Process and how to create MVP for startup products?",
        description:
            "The process of design is the secret weapon to succeed a product. Designers around the world use various strategies to design the first version of the product. However, Lean UX is the revolutionary UX design process and cost-saving activity for the startup. In the following section, I will provide an overview of the Lean UX process and How to create an MVP for a startup.",
        image: "/assets/images/lean-ux-process.png",
        url: "https://bootcamp.uxdesign.cc/lean-ux-design-process-how-to-create-mvp-for-startup-products-8949648c5291",
    },
    {
        title: "Why human-centered design (HCD) is key to understand the user behavior and technology ?",
        description: "People confused with their daily life to manage personal belongings. They has to manage daily activity through the smartphone application, dashboard, automobile and so on. Over time increase the use of automation in the home with its internal network, music video, games system for entertainment and communication. However, to use all...",
        image: "/assets/images/human-centered-design.png",
        url: "https://bootcamp.uxdesign.cc/human-centered-design-hcd-leads-user-emotion-while-interacting-with-the-products-1fa40ffa7b40",
    },
];
