/* eslint-disable jsx-a11y/anchor-is-valid */
import { useParams } from "react-router-dom";
import { WorkData } from "../../data/WorkData";
export default function WorkHero() {
    let { project_id } = useParams();
    let data = null;
    if (WorkData[project_id]) data = WorkData[project_id].overview;

    return (
        <>
            {data && <section className="dark:bg-gray-900 py-10 bg-gray-50" id={data.id}>
                <div className="container">
                    <div className="flex flex-col md:flex-row space-x-0 md:space-x-6 dark:text-white">
                        <div className="flex flex-col space-y-7 flex-1  mt-16 md:mt-0">
                            <h1 className="text-4xl font-bold font-poppins">{data.title}</h1>
                            <h1 className="text-lg">{data.description}</h1>
                            <div>
                                <a href={data.live_demo } target="_blank" className="bg-primary px-5 py-2 text-white text-base hover:border-2 hover:border-primary hover:bg-white hover:text-black transition-all duration-300 ease-linear border-2 border-transparent dark:hover:border-white" rel="noreferrer">
                                    Live Demo
                                </a>
                            </div>
                        </div>
                        <div className="flex-1 inline-flex justify-center items-center">
                            <img src={data.image} alt={data.title} />
                        </div>
                    </div>
                </div>
            </section>}
        </>
    );
}
