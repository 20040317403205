/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-scroll";

import { useEffect, useState } from "react";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { AboutData } from "../../data/AboutData";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function VerticalMenu() {
  const screenWidth = useState(window.innerWidth);

  let [show, setShow] = useState(true);
  function handleShow() {
    setShow(!show);
  }

  useEffect(() => {
    if (screenWidth[0] < 1024) {
      setShow(false);
    }
  }, []);

  return (
    <>
      <section
        className="  lg:bg-transparent p-5 fixed right-0 top-[42%] z-50"
        id="vertical_menu"
      >
        <div className=" flex space-x-1 dark:text-white">
          <div
            onClick={handleShow}
            className="bg-primary p-4 w-10 h-10 lg:hidden  flex justify-center items-center rounded-md cursor-pointer"
          >
            <button className="inline-flex justify-start items-start">
              {show ? (<GoChevronRight className=" w-5 h-5 text-white" />) : (<GoChevronLeft className=" w-5 h-5 text-white" />)}
            </button>
          </div>
          <div
            className={classNames(
              show ? "flex flex-col" : "hidden",
              "transition-all duration-300 bg-white lg:bg-transparent px-2 py-1"
            )}
          >
            {Object.keys(AboutData).map((item,idx) => {
              return (
              
                  <Link
                    activeClass="active"
                    to={AboutData[item].id}
                    className="text-[1rem] text-taupe-gray mb-3"
                    spy={true}
                    smooth={true}
                    duration={500}
                  offset={-100}
                  key={idx}
                  title={AboutData[item].menu_name}
                  >
                  
                  {AboutData[item].menu_name.length > 16 ? AboutData[item].menu_name.substring(0, 15).concat("...") : AboutData[item].menu_name}
                  </Link>
              
              );
            })}

            {/* <Link
                        activeClass="active"
                        className="test1"
                        to="skill_set"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-200}
                    >
                        Skill Set
                    </Link>
                    <Link
                        activeClass="active"
                        className="test1"
                        to="timeline"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-100}
                    >
                        Timeline and experince
                    </Link>
                    <Link
                        activeClass="active"
                        className="test1"
                        to="education"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={-100}
                    >
                        School and education
                    </Link> */}
          </div>
        </div>
      </section>
    </>
  );
}
