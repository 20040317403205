export default function BlogHero() {
    return (
        <>
            <section className="dark:bg-gray-900 py-10">
                <div className="container">
                    <div className="flex flex-col space-y-6 dark:text-white">
                        <h1 className=" text-3xl sm:text-5xl font-bold font-poppins">Blog</h1>
                        <h1>
                            During my laser time I write blog for the global comunity mostly on medium. Few of them are listed here.
                        </h1>
                        <div>
                            <a href="https://calendly.com/atikur-ux/15min" target="_blank" className="bg-primary px-5 py-2 text-white text-base hover:border-2 hover:border-primary hover:bg-white hover:text-black transition-all duration-300 ease-linear border-2 border-transparent dark:hover:border-white" rel="noreferrer">
                                Lets talk
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
