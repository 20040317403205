import { useEffect } from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

export default function NotFound() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [])
    return (
        <>
            <Header />
            <section class="col-span-6 mb-10">
                <div class="container w-7xl mx-auto">
                    <div class="mx-[.7rem] md:mx-[1rem] lg:mx-[2rem] xl:mx-[4rem] 2xl:mx-[10rem]"></div>
                    <div class="flex flex-col justify-center items-center">

                        <div>
                            <img src="/assets/images/notfound.jpg"
                                alt="" />
                        </div>
                        {/* <h1 class="text-7xl font-bold">404</h1>
                        <h1 class="text-3xl md:text-4xl lg:text-6xl font-bold text-center">Not found!</h1> */}
                    </div>

                </div>
            </section>


           <Footer/>
        </>
    )
}