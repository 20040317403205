export const TestimonialsData = [
    {
        context: "I know Atik from research methods and human-centered design courses. I also co-supervised his master's thesis on Lean UX. Atik is very smart, passionate about UX research and design and diligent. He is also a very positive person. It's nice to work and talk to him. He listens, asks very pertinent questions, and provides ingenious answers, ideas, and solutions in a timely manner. Atik is very capable of working and studying independently and does his best in all the tasks with which he is engaged.",
        name: "Dorina Rajanen 🇫🇮",
        designation: "Post doc reasearch at University of oulu",
        image: "/assets/images/dorina.png",
    },
    {
        context: "Atikur is intelligent and hardworking. He is diligent and never missed a deadline. He also contributed his insights as a UX designer. Regarding teamwork, he is very helpful and communicative.",
        name: "Solli Vu",
        designation: "Human Resources Business Partner at Huawei 🚀",
        image: "/assets/images/solli.png",
    },
    {
        context: "Atikur Rahman is a respectable and talented teammate who is always ready to be first on call when you need him. His strong work ethic aligns well with completing projects and offering valuable insights from his rich expertise. Atikur is a gold star and any venture that he does business with adds nothing but value and efficiency.",
        name: "JJ Hurstinen",
        designation: "Growth Hacker at Good Sign",
        image: "/assets/images/jj.png",
    },
    {
        context: "Atik shared really good insights about how to improve my portfolio and helped me focus more on the way I present myself in the workplace. A great mentor and also a lovely person to begin with.",
        name: "Iman Noorbakhsh 🇫🇮",
        designation: "HCI Student, at Tampere university",
        image: "/assets/images/iman.png",
    },
    {
        context: "Atikur Rahman is a respectable and talented teammate who is always ready to be first on call when you need him. His strong work ethic aligns well with completing projects and offering valuable insights from his rich expertise. Atikur is a gold star and any venture that he does business with adds nothing but value and efficiency.",
        name: "JJ Hurstinen",
        designation: "Driving growth 🚀 | Funnel alignment 💎 | CRM 🎯 | CX development 🔑 | Operations 🏗",
        image: "/assets/images/jj.png",
    },
    {
        context: "Atik shared really good insights about how to improve my portfolio and helped me focus more on the way I present myself in the workplace. A great mentor and also a lovely person to begin with.",
        name: "Iman Noorbakhsh 🇫🇮",
        designation: "HCI Student at Tampere university",
        image: "/assets/images/iman.png",
    },
]