import { AboutData } from "../../data/AboutData";
import { CVjson } from "../../data/CVjson";
export default function AboutHero() {
    let about_hero = AboutData.about_hero;
  
    return (
        <>
            <section className="dark:bg-gray-900 py-10 bg-gray-50" id={about_hero.id}>
                <div className="container">
                    <div className="flex flex-col md:flex-row space-x-0 md:space-x-6 dark:text-white">
                        <div className="flex flex-col space-y-7 w-full md:w-[65%] order-2 md:order-1 mt-16 md:mt-0">
                            <h1 className="text-4xl font-bold font-poppins">{about_hero.title}</h1>
                            <h1 className="text-xl leading-8">{about_hero.description}</h1>
                            <div>
                                <a href={CVjson.pdf} target="_blank" download className="bg-primary px-5 py-2 text-white text-base hover:border-2 hover:border-primary hover:bg-white hover:text-black transition-all duration-300 ease-linear border-2 border-transparent dark:hover:border-white" rel="noreferrer">
                                    Download CV
                                </a>
                            </div>
                        </div>
                        <div className="w-full md:w-[35%] order-1 md:order-2 inline-flex justify-center items-center">
                            <div className="shadow p-5 dark:bg-gray-600">
                                <img src={about_hero.image} alt={about_hero.title} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
